<template>
  <div class="bg--horoscopes">
    <div class="bg--horoscopes">
      <div class="container container--2">
        <div class="header">
          <router-link to="/" class="header__logo"></router-link>
          <div class="header-block">
            <router-link to="/horoscope" class="header-block__name">
              Мои гороскопы
            </router-link>
          </div>
        </div>
        <div class="horoscope">
          <h2 class="horoscope__tit-small">
            мои гороскопы
          </h2>
          <div class="horoscopes-blocks">
            <div class="horoscopes-block" v-for="horoscope in horoscopes" :key="horoscope.id">
              <div class="horoscopes-block__info">
                <h3>
                  {{ horoscope.name }}
                </h3>
                <p>
                  {{ getDescription(horoscope) }}
                  <br>
                  <br>
                  <router-link style="color: #fff;" :to="'/horoscope/' + horoscope.id">
                    Подробнее
                  </router-link>
                </p>
              </div>
              <div class="horoscopes-block__imgs horoscopes-block__imgs--man">
                <img src="@/assets/images/man.png" srcset="@/assets/images/man@2x.png @2x"
                  alt="" class="horoscopes-block__img">
                <div class="horoscopes-block__symbol-block">
                  <img :src="image[horoscope.zodiac.alias]"
                    class="horoscopes-block__symbol horoscopes-block__symbol--1">
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer-block />
      </div>
    </div>
  </div>
</template>

<script>
import aries from '@/assets/images/aries@2x.png';
import taurus from '@/assets/images/taurus@2x.png';
import gemini from '@/assets/images/gemini@2x.png';
import cancer from '@/assets/images/cancer@2x.png';
import leo from '@/assets/images/leo@2x.png';
import virgo from '@/assets/images/virgo@2x.png';
import libra from '@/assets/images/libra@2x.png';
import scorpio from '@/assets/images/scorpio@2x.png';
import sagittarius from '@/assets/images/sagittarius@2x.png';
import capricorn from '@/assets/images/capricorn@2x.png';
import aquarius from '@/assets/images/aquarius@2x.png';
import pisces from '@/assets/images/pisces@2x.png';

export default {
  name: 'Home',
  data() {
    return {
      taurus,
      aries,
      gemini,
      cancer,
      leo,
      virgo,
      libra,
      scorpio,
      sagittarius,
      capricorn,
      aquarius,
      pisces,
      horoscopes: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/horoscope', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/');
      } else {
        this.horoscopes = response.data.result;
      }
    },
    getDescription(horoscope) {
      let str = horoscope.description.replace(/(<([^>]+)>)/gi, '').substring(0, 300);
      str += '...';
      return str;
    },
  },
  computed: {
    image() {
      return {
        taurus,
        aries,
        gemini,
        cancer,
        leo,
        virgo,
        libra,
        scorpio,
        sagittarius,
        capricorn,
        aquarius,
        pisces,
      };
    },
  },
};
</script>
<style lang="scss">
.horoscope__button--top {
  margin-top: 7rem;
}
@media (max-width: 768px) {
 .horoscope__button--top {
    margin-top: 4rem;
    text-align: center;
  }
}
</style>
